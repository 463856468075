import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { LocalStorageService } from '../services/localstorage.service';
import { map } from 'rxjs/operators';
import { Cart, ModelBulkUpdateCart } from './cart.interface';
import { FormService } from '../services/form.service';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  // cart subject
  Cart: ReplaySubject<Cart> = new ReplaySubject(1);
  CartErrors: ReplaySubject<any> = new ReplaySubject(1);
  CartReasons: ReplaySubject<any> = new ReplaySubject(1);

  // ID of selected cart
  cartId: number;

  // cart object
  cart: any;

  private _cartLsKey = 'pcCartId';

  // endpoints
  public _cartEp = `${this._apiSvc.endpoints.sales}/api/Cart`;
  public _cartItemEp = `${this._apiSvc.endpoints.sales}/api/CartItem`;
  private _cartReasonsEp = `${this._apiSvc.endpoints.sales}/api/CartType`;
  public _cartModelBulkUpdateEp = `${this._apiSvc.endpoints.sales}/api/Cart/ModelBulkUpdate`;

  // subscriptions
  private _cartSub: Subscription;

  constructor(
    private _apiSvc: ApiService,
    private _formSvc: FormService,
    private _lsSvc: LocalStorageService
  ) {

    const cartId = this._lsSvc.getItem(this._cartLsKey);
    if (cartId) {
      this.cartId = cartId;
    }

    this.getCart();
    this.getCartReasons();

  }

    /**
   * Get truck 
   * @param id 
   * @returns 
   */
     get(id: number): any {
      return this._apiSvc.get(`${this._cartEp}/${id}`);
    }

    updateMBUCart(params: ModelBulkUpdateCart): any {
      return this._apiSvc.update(`${this._cartEp}/ModelBulkUpdate/${params.Id}`, params);
    }

    ProcessRetailPriceUpdate(params: ModelBulkUpdateCart): any {
      return this._apiSvc.update(`${this._cartEp}/ProcessRetailPriceUpdate/${params.Id}`, params);
    }

    /**
   * Remove item from cart
   */
  removeItemFromMBUCart(id: number): any {

    return this._apiSvc.delete(`${this._cartItemEp}/${id}`);
  }

  /**
   * Get current cart id
   */
  getCartId(): number {
    return this.cartId;
  }

  /**
   * Get cart by cart id
   * @param id
   */
  getCart(_id?: number) {

    if (!this.cartId) {
      this.Cart.next(null);
      return;
    }

    if (this._cartSub) {
      this._cartSub.unsubscribe();
    }

    this._cartSub = this._apiSvc.get(`${this._cartEp}/${this.cartId}`)
      .subscribe((res: Cart) => {
        if (res) {
          this.Cart.next(res);
          this.cart = res;
        }
      }, (err: any) => {
        const errors = this._formSvc.processFormErrors(err);
        this.CartErrors.next(errors);
      });

  }

  /**
   * Update cart data
   * @param data
   * @param refreshCart
   */
  updateCart(data: any, refreshCart: boolean = false) {

    this.CartErrors.next(null);

    this._apiSvc.update(`${this._cartEp}/${data.Id}`, data)
      .subscribe(() => {
        if (refreshCart) {
          this.getCart();
        }
      }, (err: any) => {
        const errors = this._formSvc.processFormErrors(err);
        this.CartErrors.next(errors);
      });

  }

  /**
   * Get cart reasons options
   */
  getCartReasons() {

    this._apiSvc.get(this._cartReasonsEp+'?searchText=&Filter=Id~neq~9~and~Id~neq~10~and~Id~neq~11&sort=Id-asc', 'cartReasons')
      .subscribe((res) => {
        this.CartReasons.next(res);
        this.CartReasons.complete();
      });

  }

  /**
   * Set cart id
   */
  useCart(id: number): void {

    this.cartId = id;
    this._lsSvc.setItem(this._cartLsKey, id);
    this.Cart.next(null);
    this.getCart();

  }

  /**
   * Delete cart
   * @param cartdId 
   * @returns 
   */
  deleteCart(cartdId: number): any {
    return this._apiSvc.delete(`${this._cartEp}/${cartdId}`);
  }

  /**
   * Add product model to cart
   * @param item 
   */
  addItem(item: any, addOns: any[] = []): Observable<any> {

    const itemParams = {
      ItemQty: item.ItemQty || 1,
      ItemUnitPrice: item.ItemUnitPrice,
      ItemSeq: 1,
      ModelCartHeadId: this.cartId,
      ProductModelId: item.ProductModelId,
      ItemExtPrice: 0,
      ModelType: item.ModelType || 1
    };

    let addOnParams: any[] = [];
    
    addOns.forEach((addOn) => {
      addOnParams.push({
        ItemQty: addOn.ItemQty || 1,
        ItemUnitPrice: addOn.PriceAmount,
        ItemSeq: 1,
        ModelCartHeadId: this.cartId,
        ProductModelId: addOn.ItemProductModelId,
        ItemExtPrice: addOn.PriceAmount * (addOn.ItemQty || 1),
        ProductModelGroupItemId: addOn.Id
      })
    })

    // calculate the extended price
    itemParams.ItemExtPrice = Number(itemParams.ItemQty) * Number(itemParams.ItemUnitPrice);
    

    const cartParams = {
      ModelCartItems: [
        itemParams,
        ...addOnParams
      ]
    };

    if (!this.cartId) {

      return this._apiSvc.add(this._cartEp, cartParams)
        .pipe(
          map((cart: any) => {
            this.cartId = cart.Id;
            this._lsSvc.setItem(this._cartLsKey, cart.Id);
            this.getCart();
            this.Cart.next(cart);
            this.cart = cart;
          })
        );

    } else {

      //const existingItem = this._utilsSvc.getRecordByKey(this.cart.ModelCartItems, 'ProductModelId', item.ProductModelId);

      const existingItem = this.cart.ModelCartItems.find((r: any) => {
        return r['ProductModelId'] === item.ProductModelId && r['ProductModelGroupItemId'] === null;
      });

      if (existingItem) {
        existingItem.ItemQty += itemParams.ItemQty;
        existingItem.Addons = addOnParams;
        return this._apiSvc.update(`${this._cartItemEp}/${existingItem.Id}`, existingItem);
      }

    return this._apiSvc.add(`${this._apiSvc.endpoints.sales}/api/Cart/${this.cartId}/${addOns.length > 0 ? 'Items' : 'Item'}`, addOns.length > 0 ? cartParams.ModelCartItems : itemParams);
    }

  }

  /**
   * Update item in cart
   * @param item
   */
  updateItem(item: any): void {

    if (!this.cartId) {
      console.error('No cart selected');
      return;
    }

    const ep = `${this._cartItemEp}/${item.Id}`;

    this._apiSvc.update(ep, item)
      .subscribe(() => {
        this.getCart();
      }, (err: any) => {
        const errors = this._formSvc.processFormErrors(err);
        this.CartErrors.next(errors);
      });

  }

  /**
   * Remove item from cart
   */
  removeItem(id: number): void {

    if (!this.cartId) {
      console.error('No cart selected');
      return;
    }

    const ep = `${this._cartItemEp}/${id}`;

    this._apiSvc.delete(ep)
      .subscribe(() => {
        this.getCart();
      }, () => {
        this.getCart();
      });

  }

  /**
   * Discount item request
   * @param itemId 
   * @param payload 
   * @returns 
   */
  discountItem(itemId: number, payload: any): any {
    const ep = `${this._cartItemEp}/${itemId}/Discount`;
    return this._apiSvc.update(ep, payload);    
  }

  /**
   * Remove item discount amount
   * @param itemId 
   * @returns 
   */
  removeItemDiscount(itemId: number): any {
    const ep = `${this._cartItemEp}/${itemId}/RemoveDiscount`;
    return this._apiSvc.update(ep, {});  }

  /**
   * Create new cart
   */
  newCart(): void {

    this._apiSvc.add(this._cartEp, {})
      .subscribe((res: Cart) => {
        this.useCart(res.Id);
      });
  }

  /**
   * Create new cart, return observable
   */
  addCart(params: any): Observable<any> {
    return this._apiSvc.add(this._cartEp, params);
  }

  /**
   * Remove cart data
   */
  resetCart(): void {
    this._lsSvc.removeItem(this._cartLsKey);
    this.cart = null;
    this.cartId = null;
    this.Cart.next(null);
  }

  createModelBulkUpdateCart(criteria: any):any{
    return this._apiSvc.add(this._cartModelBulkUpdateEp,criteria)
  }

  applyDiscountWithPercentage(percentDiscount: number): any {
    return this._apiSvc.update(`${this._cartEp}/${this.cartId}/ApplyDiscount`, {PercentDiscount: percentDiscount});
  }

  applyDiscountWithNewAmount(newAmount: number): any {
    return this._apiSvc.update(`${this._cartEp}/${this.cartId}/ApplyDiscount`, { NewAmount: newAmount });
  }

  getCartTotalsWithoutDiscount(): any {
    return this._apiSvc.get(`${this._cartEp}/${this.cartId}/TotalsWithoutDiscount`);
  }

  removeDiscount(): any {
    return this._apiSvc.update(`${this._cartEp}/${this.cartId}/RemoveDiscount`, {});
  }
}
